import React from "react"
// import { Link } from "gatsby"
import '../styles/global.css'
import phoneImg from '../images/phoneImg.png'
import watch from '../images/watch.png'
import underLine from '../images/line1.png'
import rectangleImg from '../images/rectangle1.png'
import vector1 from '../images/Vector1.png'
import vector2 from '../images/Vector2.png'
import vector3 from '../images/Vector3.png'

import Transition from "../components/transition"
import Layout from "../components/layout"
import Brands from "../components/brands"
import Connect from "../components/connect"
import { Link } from "gatsby"



const Index = () => (
  <Layout>
    <div className="bg-grey max-h-full pb-36" id="home">
      <div className="flex justify-center">
      <section className=" section1 w-full">

        <div className="rectangle1 bg-white shadow-md">
          <div className="section1Grid sm:grid sm:grid-cols-2 grid grid-cols-1">
            <div className="">
              <div className="flex justify-center items-center">
                <div>
                  <h1 className="head1 font-sora font-semibold">
                    One of  South India’s <br/><span className="text-green">Largest Supplier</span> of <br/>Modern Gadgets. 
                  </h1>
                  <p className="paragraph1 font-sora font-light">
                    We offer great deals on new and most unique gadgets available in South India. 
                    Gadgets include Computer accessories, Headphones, Headsets, Speakers, Smart watches, 
                    Cameras, and a lot more. 
                  </p>
                  <div className="buttonDiv2 flex justify-start">
                      <Link to="#contactUs"><button className="submitButton2 bg-green hover:bg-grey text-white font-semibold">
                        Get in touch
                      </button></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <img className="watch" src={watch} alt="" />
            </div>
          </div>

        </div>

      </section></div>

      <section className="section2 w-full">
        <div className="flex justify-center">
          <img className="phoneImg" src={phoneImg} alt="" />
        </div>
        <div className="flex justify-center">
          <div className="">
            <h1 className="head2 font-sora font-semibold text-green text-center">
              The Pioneer in Modern Gadgets
            </h1>
            <p className="paragraph2 font-sora text-center">
              We are the leading wholesale mobile accessories 
              supplier in the Middle East.As customers are our priority, 
              we offer the best in class products with exciting offers and deals. The belief of 
              our customers and satisfaction of our customers are the
              two factors that drive us to deliver impeccable services 
              and our success too. We deal in licensed and branded 
              wholesale products.
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <img className="line1" src={underLine} alt="" />
        </div>

      </section>

      <section className="section3 flex justify-center" id="service">
      <div className="rectangle2 bg-white">
        <div className="">
          <div className="flex justify-center">
            <div className="section3Grid sm:grid sm:grid-cols-2 grid grid-cols-1">
              <div>
                <Link to="/"><h1 className="head3 font-medium font-sora"><span className="text-green">Services</span> We Provide</h1></Link>
                <p className="paragraph3 font-sora font-light">Line Touch is now been recognised as one of UAE's most trusted suppliers. 
                  We source all our products from reputable and authorised manufactures / distributors.</p>
              </div>
              <div className="">
                <img className="image2" src={rectangleImg} alt="" />
              </div>
            </div>
          </div>
        </div>
        
        <div className="section4">
          <div className="flex justify-center">
            <div className="section4Grid sm:grid sm:grid-cols-3 grid grid-cols-1">
              <div className="openBox flex items-center ">
                <div>
                  <div className="flex justify-center">
                    <img className="sec4Img" src={vector1} alt="" />
                  </div>
                  <div className="sec4Items font-sora">
                    <Link to="/"><h1 className="sec4head text-center text-green">Wholesale</h1></Link>
                    <p className="sec4Para font-light text-center">We bring you high-quality, in-vogue wholesale mobile 
                      accessories at a reasonable price. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="openBox flex items-center">
              <div>
                  <div className="flex justify-center">
                    <img className="sec4Img" src={vector2} alt="" />
                  </div>
                  <div className="sec4Items font-sora">
                    <Link to="/"><h1 className="sec4head text-center text-green">Products</h1></Link>
                    <p className="sec4Para font-light text-center">We provide the best and genuine products giving our 
                      customers as a satisfied one.
                    </p>
                  </div>
                </div>
              </div>
              <div className="openBox flex items-center">
              <div>
                  <div className="flex justify-center">
                    <img className="sec4Img" src={vector3} alt="" />
                  </div>
                  <div className="sec4Items font-sora">
                    <Link to="/"><h1 className="sec4head text-center text-green">Discounts</h1></Link>
                    <p className="sec4Para font-light text-center">We provide the lowest prices and great offers compared to prices 
                      in the market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      </section>
      
      <Transition/>
      
      <Brands/>
      
      <Connect/>
      

      
      
    </div>
  </Layout>
)

export default Index 
