import { Link } from 'gatsby'
import React from 'react'
import lineTouchLogo from '../images/logo.png'
import spBoxLogo from '../images/spBox.png'
import '../styles/footer.css'

const Footer = () => {
    return (
        <div>
            
            <section id="footer">
                <div>
                    
                    <div className="flex justify-center">
                        <Link to="#header"><img className="logo" src={lineTouchLogo} alt="" /></Link>
                    </div>
                    
                    <div className="flex justify-center items-center">
                        <h1 className="ownerText font-tajawal">DESIGNED AND <br/>DEVELOPED BY</h1>
                        <Link to="https://speakerbox.in/"><img className="flex justify-center items-center" src={spBoxLogo} alt="" /></Link>
                    </div>
                    
                    <div className="flex justify-center items-center">
                        <h1 className="copyrightText font-sora">© 2021 © Line Touch LLP ©  All Rights Reserved</h1>
                    </div>
                         
                </div>
            </section>

        </div>
    )
}

export default Footer
