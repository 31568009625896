import React from 'react'
import '../styles/transition.css'
import photo1 from '../images/tra1.jpg'
import photo2 from '../images/tra2.jpg'
import photo3 from '../images/tra3.jpg'
import photo4 from '../images/tra4.jpg'
// import photo5 from '../images/photo5.jpeg'

const Transition = () => {
    return (
        <div>
          
          <section className="transition flex justify-center " id="products">
            <div>
              <nav class="nav-container">
                <ul class="nav-lists">
                  <li class="nav-list1">
                    <img className="imagesImg" src={photo1} alt="" />
                  </li>
                  <li class="nav-list2" >
                    <img className="imagesImg" src={photo2} alt="" />
                  </li>
                  <li class="nav-list3" >
                    <img className="imagesImg" src={photo3} alt="" />
                  </li>
                  <li class="nav-list4" >
                    <img className="imagesImg" src={photo4} alt="" />
                  </li>
                </ul>
              </nav>           
            </div>
          </section>

          <section className="bottomSquare">
            <div>
              <div className="squareShape1 flex justify-center items-center">
                <h1 className="text text-white font-sora">
                  Delivering you the best collection of modern gadgets
                </h1>
              </div>
              <div className="squareShape2 bg-green"></div>
            </div>
          </section>

          





        </div>
    )
}

export default Transition
