import React from 'react'
import '../styles/brands.css'
import brand1 from '../images/brand1.png'
import brand2 from '../images/brand2.png'
import brand3 from '../images/brand3.png'
import brand4 from '../images/brand4.png'
import brand5 from '../images/brand5.png'
import brand6 from '../images/brand6.png'
import brand7 from '../images/brand7.png'
import brand8 from '../images/brand8.png'
import brand9 from '../images/brand9.png'
import brand10 from '../images/brand10.png'
import brand11 from '../images/brand11.png'
import love from '../images/loveemoji.png'



const Brands = () => {
    return (
        <div>
            
            <section className="brands flex justify-center">
                
                <div className="brandsRectangle">
                    <div className="brandsDiv flex justify-center items-center">
                        <h1 className="BrandsWeText font-medium font-sora">Brands we</h1>
                        <img className="loveEmogi" src={love} alt="" />
                    </div>
                    <div className="flex justify-center items-center">
                    
                    <div className="brandsGrid grid grid-cols-2 sm:grid sm:grid-cols-4 items-center">
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg2" src={brand1} alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg" src={brand2} alt="" />
                        </div>
                        <div className="flex justify-center items-center"> 
                            <img className="brandLogoImg" src={brand3} alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg" src={brand4} alt="" />
                        </div>
                    </div>
                    </div>
                    
                    <div className="flex justify-center items-center">
                    <div className="brandsGrid grid grid-cols-2 sm:grid sm:grid-cols-4 items-center">
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg" src={brand5} alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg" src={brand6} alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg2" src={brand7} alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg" src={brand8} alt="" />
                        </div>
                    </div>
                    </div>
                    
                    <div className="flex justify-center items-center">
                    <div className="brandsGrid grid grid-cols-2 sm:grid sm:grid-cols-3 items-center">
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg" src={brand9} alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg" src={brand10} alt="" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="brandLogoImg flex justify-center" src={brand11} alt="" />
                        </div>
                    </div>
                    </div>
                </div>
                
            </section>
        </div>
    )
}

export default Brands
