import React from 'react'
import '../styles/contact.css'
import rectangle from '../images/Rectangle 13.png'
import location from '../images/location.png'
import phone from '../images/phone.png'

const Connect = () => {
    return (
        <div id="contactUs">
            <h1>
            <section className="contact flex justify-center">
                
                <div className="contactRectangle relative">
                    <div className="rectangleDiv">
                        <img className="rectangleImg sm:block hidden" src={rectangle} alt="" />
                    </div>
                    <div className="flex justify-center sm:block sm:justify-start" action="https://getform.io/f/ed106142-941e-453b-a529-24a82666c583" method="POST">
                        <div className="formDiv">
                            <h1 className="letsConnect font-sora text-green">Let’s Connect</h1>
                            <form action="https://getform.io/f/ed106142-941e-453b-a529-24a82666c583" method="POST">
                                <div className="gridItems1 sm:grid sm:grid-cols-2 grid grid-cols-1">
                                    <div>
                                        <div className="">
                                            <label className="label1 block  text-green font-semibold" for="forms-labelOverInputCode">Name</label>
                                            <input className="inputType1 block appearance-none bg-white shadow leading-tight focus:outline-none focus:shadow-outline" type="text" name="Name" placeholder="Your Name"></input>
                                        </div>
                                    </div>
                                    
                                    <div className="secondInputDiv">
                                        <div className="">
                                            <label className="label1 block text-green font-semibold" for="forms-labelOverInputCode">Company</label>
                                            <input className="inputType1 block appearance-none   bg-white  shadow leading-tight focus:outline-none focus:shadow-outline" type="text" name="Company Name" placeholder="Company Name"></input>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="gridItems2 sm:grid sm:grid-cols-2 grid grid-cols-1">
                                    <div>
                                        <div className="">
                                            <label className="label1 block text-green font-semibold" for="forms-labelOverInputCode">Phone</label>
                                            <input className="inputType1 block appearance-none   bg-white  shadow leading-tight focus:outline-none focus:shadow-outline" type="text" name="Phone Number" placeholder="eg: 1234567890"></input>
                                        </div>
                                    </div>
                                    
                                    <div className="secondInputDiv">
                                        <div className="">
                                            <label className="label1 block text-green font-semibold" for="forms-labelOverInputCode">Email</label>
                                            <input className="inputType1 block appearance-none   bg-white  shadow leading-tight focus:outline-none focus:shadow-outline" type="email" name="Email" placeholder="name@gmail.com"></input>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="gridItems2 flex mt-4">
                                    <div>
                                        <div className="">
                                            <label className="label1 block text-green font-semibold" for="forms-labelOverInputCode">Message</label>
                                            <textarea className="textArea block appearance-none w-full bg-white  shadow leading-tight focus:outline-none focus:shadow-outline" name="Message" placeholder="Write a message..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="buttonDiv sm:flex sm:justify-end flex justify-center">
                                    <button className="submitButton bg-green hover:bg-grey text-white font-semibold">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                
            </section>

            <section className="address">
                <div className="flex justify-center items-center">
                    <div className="addressGrid grid grid-cols-1 sm:grid sm:grid-cols-2">
                        <div className="flex items-center justify-center">
                            <img className="vectorImages1" src={location} alt="" />  
                            <h1 className="locationContent font-sora font-semibold">No. 348, BEML Layout, 2nd Floor, 2nd Cross, 9th Main, 6th Stage, Thuburahalli, Bengaluru - 560066</h1>
                        </div>
                        <div className="flex items-center justify-center">
                            <img className="vectorImages2" src={phone} alt="" />
                            <h1 className="phoneContent font-sora font-semibold">+91 9207092983</h1>
                        </div>
                    </div>
                </div>
            </section>
            </h1>
        </div>
    )
}

export default Connect
