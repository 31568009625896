import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Helmet } from 'react-helmet'


const Layout = (props) => {
  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Line Touch India | Gadgets and Mobile Accessories Supplier</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
      <Header />
      {props.children}
      <Footer/>
    </div>
  )
}

export default Layout
