import logo from '../images/logo.png'
import React, { Fragment } from 'react'
import { Menu, Transition } from "@headlessui/react";
import { XIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby'
import instagram from '../images/instagram.png'
import facebook from '../images/facebook.png'
import '../styles/global.css'


const navigation = [
  { name: 'HOME', href: '#header', current: true },
  { name: 'SERVICES', href: '#service', current: false },
  { name: 'PRODUCTS', href: '#products', current: false },
  { name: 'CONTACT US', href: '#contactUs', current: false },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Header() {
  return (
    

    <Menu as="nav" className="relative z-10 " id="header">
      {({ open }) => (
        <Fragment>
          <div className="mx-auto px-6 py-6 lg:px-1 md:px-16 sm:px-12 bg-white flex justify-center shadow-md">
            <div className="relative flex items-center justify-between h-16 xl-max-width w-full">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Menu.Button className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-black focus:outline-none focus:bg-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuBar className="block h-6 w-6" aria-hidden="true" />

                  )}
                </Menu.Button>
              </div>
              <div className="headerGrid flex items-center  sm:items-center justify-start lg:justify-between xl:px-40 lg:px-28  w-screen ">
                <Link
                  to="#header"
                  className="flex items-center">
                  <img
                    className="w-auto h-12 sm:w-auto sm:h-12 md:h-16 md:w-auto lg:h-12 lg:w-auto"
                    src={logo} alt="Line Touch"
                  />
                  
                </Link>

                <div className="hidden lg:block sm:ml-6 flex items-center">

                  <div className="flex space-x-5 mt-4">
                    {navigation.map((item) => (
                      
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? 'text-black font-sora font-bold hover:text-green' : 'text-black hover:text-gray-200 font-sora',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>


                    ))}
                    <div className="flex items-center gap-5">
                      <Link to="https://www.facebook.com/login/"><img className="w-5" src={facebook} alt="" /></Link>
                      <Link to="https://www.instagram.com/"><img className="w-5" src={instagram} alt="" /></Link>
                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-100"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="lg:hidden absolute w-full bg-white focus:outline-none focus:ring-0 shadow-md">
              <div className="px-2 pt-2 pb-3 space-y-1 focus:outline-none">
                {navigation.map((item) => (
                  <Menu.Item>
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current ? 'text-black font-sora' : 'text-black hover:bg-gray-700 hover:text-black font-sora',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Link>
                  </Menu.Item>
                ))}
                <Menu.Item>
                  <div className="">
                    <Link to="/"><img className="w-5  ml-3" src={facebook} alt="" /></Link>
                    <Link to="/"><img className="w-5 mt-4 ml-3" src={instagram} alt="" /></Link>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Fragment>
      )}
    </Menu>
  )
}

export default Header

function MenuBar(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
    </svg>
  )
}